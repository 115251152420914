
import Vue from 'vue'
import { mapActions } from 'vuex'
import CategoryBestListView from '@/components/views/category/CategoryBestList.vue'
import { getHistory } from '@/helper/category'

export default Vue.extend<Data, Methods, Computed>({
  name: 'CategoryBestList',
  components: {
    CategoryBestListView,
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('CategoryStore', [
      'fetchCategoryMain',
      'fetchInitCategoryHistory',
      'fetchCategoryHistory',
    ]),
    async fetchData() {
      const history: CategoryHistory | null = getHistory()
      if (history && history.page.currentPage === this.$route.path.slice(1)) {
        this.fetchCategoryHistory(history)
      } else {
        this.fetchInitCategoryHistory()
        this.fetchCategoryMain()
        this.fetchCategoryHistory({ page: { categoryCode: '7' } })
      }
    },
  },
})

interface Data {}
interface Methods {
  // Store
  fetchLoading(payload: { yn: boolean }): Promise<void>
  fetchInitCategoryHistory(): Promise<void>
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  fetchCategoryMain(): Promise<void>
  // Component
  fetchData(): void
}
interface Computed {}
